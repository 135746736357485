import apiClient from '@/api/ApiClient'
import ResponseHandler from '../response/ResponseHandler'

const baseUrl = '/api/v1/command'

class CommandApiClient {
    orderItemRecalculateFields(from) {
        return ResponseHandler.handle(
            apiClient.post(`${baseUrl}/order-item-recalculate`, {
                from,
            })
        )
    }

    orderItemRecalculateProgress() {
        return ResponseHandler.handle(
            apiClient.get(`${baseUrl}/order-item-recalculate-progress`)
        )
    }
}

export default new CommandApiClient()
