<template>
    <div>
        <settings-form></settings-form>
    </div>
</template>

<script>
import SettingsForm from '@/components/pages/settings/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        SettingsForm,
    },
    mixins: [dirtyForm],
}
</script>
