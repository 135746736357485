<!-- eslint-disable vue/no-v-html -->
<template>
    <div>
        <v-form ref="form" autocomplete="off">
            <page-title>{{ $t('menu.settings') }}</page-title>
            <overlay :show="busy">
                <form-panel>
                    <v-row>
                        <v-col
                            v-for="(value, name) in form"
                            :key="name"
                            cols="12"
                            md="6"
                            lg="4"
                            class="pr-6"
                        >
                            <text-input
                                :name="name"
                                :value="value"
                                :label="`settings.${name}`"
                                @input="setValue(name, $event)"
                            ></text-input>
                        </v-col>
                    </v-row>
                </form-panel>
            </overlay>
            <order-item-recalculate></order-item-recalculate>
            <portal to="footer">
                <v-btn
                    color="primary"
                    class="white--text"
                    depressed
                    @click="submit"
                >
                    {{ $t('actions.save') }}
                </v-btn>
            </portal>
        </v-form>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import baseFormType from '@/store/type/baseFormType'
import SettingsApiClient from '@/api/RestApi/SettingsApiClient'
import FormPanel from '@/components/form/FormPanel'
import Overlay from '@/components/overlay/Overlay'
import PageTitle from '@/components/layout/components/pageTitle'
import TextInput from '@/components/form/Inputs/TextInput'
import OrderItemRecalculate from './panels/OrderItemRecalculate'

export default {
    components: {
        FormPanel,
        TextInput,
        Overlay,
        PageTitle,
        OrderItemRecalculate,
    },
    data() {
        return {
            form: {},
        }
    },
    computed: {
        ...mapGetters({
            busy: `baseForm/${baseFormType.getters.BUSY}`,
        }),
    },
    mounted() {
        this.setBusy(true)
        SettingsApiClient.get().then((data) => {
            this.$set(this, 'form', data)
            this.setBusy(false)
        })
    },
    methods: {
        ...mapMutations({
            setBusy: `baseForm/${baseFormType.mutations.SET_BUSY}`,
        }),
        setValue(name, $event) {
            this.form[name] = $event
        },
        submit() {
            this.setBusy(true)
            SettingsApiClient.update(this.form).then(() => {
                this.setBusy(false)
            })
        },
    },
}
</script>
