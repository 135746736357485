<template>
    <div>
        <restaurant-highlights-form></restaurant-highlights-form>
    </div>
</template>

<script>
import RestaurantHighlightsForm from '@/components/pages/settings/RestaurantHighlightsForm'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        RestaurantHighlightsForm,
    },
    mixins: [dirtyForm],
}
</script>
