<template>
    <div>
        <v-form ref="form" autocomplete="off">
            <page-title>{{ $t('menu.restaurant_highlights') }}</page-title>
            <overlay :show="busy">
                <form-panel>
                    <form-one-col-row>
                        <h3 class="headline black--text mb-3">
                            {{ $t('labels.featured') }}
                        </h3>
                        <entity-select-input
                            v-model="form.featured"
                            :entity="selectApiClientType.RESTAURANT"
                            :height="64"
                            label="labels.featured"
                            use-id-field
                            multi-select
                            autocomplete
                        ></entity-select-input>
                    </form-one-col-row>
                    <form-one-col-row>
                        <h3 class="headline black--text mb-3">
                            {{ $t('labels.popular') }}
                        </h3>
                        <entity-select-input
                            v-model="form.popular"
                            :entity="selectApiClientType.RESTAURANT"
                            :height="64"
                            label="labels.popular"
                            use-id-field
                            multi-select
                            autocomplete
                        ></entity-select-input>
                    </form-one-col-row>
                </form-panel>
            </overlay>
            <portal to="footer">
                <v-btn
                    color="primary"
                    class="white--text"
                    depressed
                    @click="submit"
                >
                    {{ $t('actions.save') }}
                </v-btn>
            </portal>
        </v-form>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import SelectApiClientType from '@/api/SelectApiClientType'
import baseFormType from '@/store/type/baseFormType'
import Overlay from '@/components/overlay/Overlay'
import PageTitle from '@/components/layout/components/pageTitle'
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'

export default {
    components: {
        Overlay,
        PageTitle,
        FormPanel,
        FormOneColRow,
        EntitySelectInput,
    },
    data() {
        return {
            form: {},
        }
    },
    computed: {
        ...mapGetters({
            busy: `baseForm/${baseFormType.getters.BUSY}`,
        }),
        selectApiClientType: () => SelectApiClientType,
    },
    mounted() {
        this.setBusy(true)
        RestaurantsApiClient.getHighlights().then((data) => {
            this.$set(this, 'form', data)
            this.setBusy(false)
        })
    },
    methods: {
        ...mapMutations({
            setBusy: `baseForm/${baseFormType.mutations.SET_BUSY}`,
        }),
        setValue(name, $event) {
            this.form[name] = $event
        },
        submit() {
            this.setBusy(true)
            RestaurantsApiClient.updateHighlights(this.form).then(() => {
                this.setBusy(false)
            })
        },
    },
}
</script>
