<template>
    <form-panel :title="$t('labels.order_item_recalculate_fields')">
        <v-row>
            <v-col cols="12" md="5" class="py-0">
                <date-picker-input
                    v-model="from"
                    v-validate="'required'"
                    label="labels.from"
                    name="commandOrderItem_from"
                    :error-messages="errors.collect('commandOrderItem_from')"
                    :data-vv-as="$t('labels.from')"
                    only-date
                ></date-picker-input>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn
                    color="primary"
                    class="white--text"
                    depressed
                    :disabled="busy"
                    :loading="busy"
                    @click="submit"
                >
                    {{ $t('actions.send') }}
                </v-btn>
            </v-col>
            <v-col cols="12" md="5">
                <v-progress-linear
                    v-show="busy"
                    :value="progress"
                    height="10"
                    buffer-value="0"
                    stream
                ></v-progress-linear>
            </v-col>
        </v-row>
    </form-panel>
</template>

<script>
import CommandApiClient from '@/api/RestApi/CommandApiClient'
import FormPanel from '@/components/form/FormPanel'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

export default {
    components: { FormPanel, DatePickerInput },
    data() {
        return {
            from: null,
            busy: false,
            total: null,
            count: null,
        }
    },
    computed: {
        progress() {
            return this.total > 0 ? (this.count / this.total) * 100 : 0
        },
    },
    watch: {},
    mounted() {
        this.updateProgress()
    },
    methods: {
        parseApi(res) {
            this.from = res.from
            this.total = res.total
            this.count = res.count
            this.busy = res.status !== 'idle' && res.total > 0

            if (this.busy) {
                setTimeout(this.updateProgress, 5000)
            }
        },
        updateProgress() {
            CommandApiClient.orderItemRecalculateProgress().then(this.parseApi)
        },
        submit() {
            this.$validator.validate('commandOrderItem_from').then((res) => {
                if (!res) return

                this.busy = true
                CommandApiClient.orderItemRecalculateFields(this.from)
                    .then(this.parseApi)
                    .catch(() => {
                        this.busy = false
                    })
            })
        },
    },
}
</script>
